import React, { useEffect, useState } from "react";
import { PaginationNav1 } from "../../components/Pagination";
import axios from "axios";
import { FiSearch } from "react-icons/fi";

const baseURL = "http://192.168.127.61:5000/api";

const categories = [
  {
    label: "All categories",
    classes:
      "text-blue-700 hover:text-white border-blue-600 bg-white hover:bg-blue-700 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800",
  },
  {
    label: "+",
    classes:
      "text-gray-900 border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 dark:text-white dark:focus:ring-gray-800",
  }
];

function Dashboard() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleDeleteImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async() => {
    const userid = JSON.parse(localStorage.getItem("user"))._id;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      await axios.post(`${baseURL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          userid: userid,
        },
      });
      files.map((one, index)=>{
        handleDeleteImage(0);
      })
      
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleClick = (url) => {
    setSelectedImage(url);
  };
  
  const fetchImageData = async () => {
    try {
      axios.get(`${baseURL}/photos`).then(async (response) => {
        setpageCount(Math.floor(response.data.length / 18) + 1);
        if ((pageIndex + 1) * 18 > response.data.length) {
          await setImageUrl(response.data.slice(pageIndex * 18, response.data.length));
        } else {
          await setImageUrl(response.data.slice(pageIndex * 18, pageIndex * 18 + 18));
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchImageData();
  }, [pageIndex]);

  return (
    <>
      <div className="container mx-auto pt-8 pb-16 px-4">
        <div className="flex flex-col sm:flex-row justify-center items-center mb-8 sm:mb-16">
          <div className="flex flex-wrap justify-center sm:justify-start">
            {categories.map((category, index) => (
              <button
                key={index}
                type="button"
                className={`bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800 font-medium py-2 px-4 rounded-full mr-2 mb-2 ${category.classes}`}
              >
                {category.label}
              </button>
            ))}
          </div>
          <div className="flex items-center mt-4 sm:mt-0 ml-0 sm:ml-auto">
            <div className="relative w-full sm:w-64 mr-4">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <input
                id="search"
                type="text"
                placeholder="Search"
                className="block w-full bg-gray-100 border-transparent rounded-md px-4 py-2 pl-12 pr-2 placeholder-gray-500 focus:bg-white focus:border-gray-500 focus:ring-0 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                <FiSearch
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <button className="bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium py-2 px-4 rounded-md">
              <span className="hidden sm:inline-block">Search</span>
              <FiSearch className="h-5 w-5 sm:hidden" aria-hidden="true" />
            </button>
          </div>
        </div>
        {/* <button onClick={fetchImageData}>fewfefeefa</button> */}

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/4 px-2 py-8">
            <div className="flex flex-wrap items-center justify-center gap-2">
              {imageUrl.map((one) => (
                <div
                  key={one._id}
                  className="relative"
                  onClick={() =>
                    handleClick(
                      `http://192.168.127.61:5000/public/image/${one.userID}/${one.filename}`
                    )
                  }
                >
                  <img
                    className={`rounded-lg w-40 h-40 object-cover `}
                    src={`http://192.168.127.61:5000/public/image/${one.userID}/${one.filename}`}
                    alt={one.filename}
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-20 text-white text-center py-0.3">
                    <p className="text-sm">{one.filename}</p>
                  </div>
                </div>
              ))}
            </div>
            {selectedImage && (
              <Modal onClose={() => setSelectedImage(null)}>
                <img
                  src={selectedImage}
                  alt="Selected image"
                  className="max-w-80 max-h-80 object-contain mx-auto"
                />
              </Modal>
            )}

            <div className="flex gap-3 flex-wrap items-center justify-center p-6 py-12">
              <PaginationNav1
                gotoPage={setPageIndex}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < pageCount - 1}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            </div>
          </div>

          <div className="hidden lg:block border-l border-gray-300 h-full my-8"></div>

          <div className="w-full lg:w-1/4 px-2 py-8">
            <div className="pb-8 mb-8">
              <div className="flex flex-col items-center justify-center space-y-4 md:space-x-4">
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="dropzone-file"
                    className="inline-flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                  >
                    <div className="flex flex-col items-center justify-center pt-3 pb-4">
                      <svg
                        className="w-8 h-8 mb-2 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <p className="mb-1 text-sm text-gray-500">
                        <span className="font-semibold">Choose file</span> or drag and drop here
                      </p>
                      <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>

                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".jpg, .png, .jpeg, .gif, .svg, .bmp, .webp"
                      multiple
                      onChange={handleFileChange}
                    />
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full" onClick={handleUpload}>Upload</button>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-8 justify-center">
              {files.map((file, index) => (
                <div key={index} className="relative w-20 h-20 p-1">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Uploaded Image ${index}`}
                    className="rounded-lg w-full h-full object-cover"
                  />
                  <button
                    onClick={() => handleDeleteImage(index)}
                    className="absolute top-0 right-0 p-1 bg-white-800 text-gray rounded-full hover:bg-red-700 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Modal({ onClose, children }) {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full  flex items-center justify-center bg-black bg-opacity-50 z-10"
      onClick={onClose}
    >
      <div
        className="bg-white p-4 rounded-lg shadow-md"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <span className="sr-only">Close</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
}
export default Dashboard;
